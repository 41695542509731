// App.js

import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import AuthenticationGuard from "./components/authentication-guard";
import Nav from "./Nav";

// ========== Lazy Imports Instead of Direct Imports ==========
const Main = lazy(() => import("./components/Main"));
const Symbols = lazy(() => import("./components/Symbols"));
const SymbolSearch = lazy(() => import("./components/SymbolSearch"));
const SymbolDetails = lazy(() => import("./components/Details"));
const CreateSymbol = lazy(() => import("./SymbolComponents/CreateSymbol"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Controlpanel = lazy(() => import("./components/Controlpanel"));
const Collections = lazy(() => import("./components/Collections"));
const PricePredictionPage = lazy(() => import("./components/PricePredictionPage"));
const Filter = lazy(() => import("./components/Filter"));
const DetailsChartOnly = lazy(() => import("./components/DetailsChartOnly"));

function App() {
  const { isLoading } = useAuth0();

  // If Auth0 is still checking the session, show PageLoader
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <Nav />

      {/* Suspense fallback is shown while any lazy component is loading */}
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {/* Public route */}
          <Route path="/" element={<Main />} />

          {/* Authenticated routes (wrapped with AuthenticationGuard) */}
          <Route
            path="/dashboard/controlpanel"
            element={<AuthenticationGuard component={Controlpanel} />}
          />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="/symbols"
            element={<AuthenticationGuard component={Symbols} />}
          />
          <Route
            path="/filter"
            element={<AuthenticationGuard component={Filter} />}
          />
          <Route
            path="/details/:symbol"
            element={<AuthenticationGuard component={SymbolDetails} />}
          />
          <Route
            path="/details/"
            element={<AuthenticationGuard component={SymbolSearch} />}
          />
          <Route
            path="/create"
            element={<AuthenticationGuard component={CreateSymbol} />}
          />
          <Route
            path="/collections"
            element={<AuthenticationGuard component={Collections} />}
          />
          <Route
            path="/predictor/:symbol"
            element={<AuthenticationGuard component={PricePredictionPage} />}
          />
          <Route
            path="/details_charts/:symbol"
            element={<AuthenticationGuard component={DetailsChartOnly} />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
